import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LocalLoaderComponent } from './local-loader.component';

@Component({
  selector: 'ta-tagserver-loader',
  template: `
    <ta-local-loader [show]="true"></ta-local-loader>
  `,
  imports: [LocalLoaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderPageComponent {}
